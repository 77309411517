import accounting from 'accounting'
import _ from 'lodash'
import moment from 'moment'

// route GoTo
export function goTo(routerInstance: any, url: string): void {
  routerInstance.resolve(url)
}

// Accounting formatter
export function formatMoney(money: number | string, currency: string = ''): any {
  return accounting.formatMoney(money, _.upperCase(currency) + ' ', 2)
}
export function formatNumber(number: any): any {
  return accounting.formatNumber(number)
}
export function unformatMoney(money: any): any {
  return accounting.unformat(money)
}
export function unformatNumber(number: any): any {
  return accounting.unformat(number)
}

// Moment
export function now() {
  return moment().format('YYYY-MM-DD')
}

export function toFullDate(date: string) {
  return moment(date).format('DD MMMM YYYY')
}

export function toMediumDate(date: string) {
  return moment(date).format('DD MMM YYYY')
}

export function toISODate(date: string) {
  return moment(date).format('YYYY-MM-DD')
}

// Scroll to Top
export function scrollToTop(scrollDuration: number = 250) {
  let cosParameter: number = window.scrollY / 2,
    scrollCount: number = 0,
    oldTimestamp: number = performance.now()
  const step = (newTimestamp: number) => {
    scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
    if (scrollCount >= Math.PI) window.scrollTo(0, 0)
    if (window.scrollY === 0) return
    window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
    oldTimestamp = newTimestamp
    window.requestAnimationFrame(step)
  }
  window.requestAnimationFrame(step)
}
