import BaseService from '@/services/BaseService'
// import orderBy = require('lodash/orderBy');

class OrdersService extends BaseService {
  constructor() {
    super()
  }

  async getOrders(opts = {}) {
    // this.endPoint = "api/orders";
    this.endPoint = 'api/v2/orders/admin'
    // https://api2dev.asiacommerce.net/api/v2/orders/admin?page[num]=2&page[limit]=30
    // https://api2dev.asiacommerce.net/api/orders?ordered_by_user=false&limit=10&page=1
    const response = await this.get(opts)
    return response.data
  }

  async getOneOrder(id: any, opts = {}) {
    // https://api2dev.asiacommerce.net/api/orders?ordered_by_user=false&limit=10&page=1&sortedBy=desc&orderBy=created_at&include=details.ref.images,details.ref.variation,user
    // this.endPoint = "api/orders";
    this.endPoint = 'api/v2/orders'
    const response = await this.getOne(id, opts)
    return response.data
  }

  async getByIdPacking(opts = {}) {
    // https://api2dev.asiacommerce.net/api/orders?ordered_by_user=false&limit=10&page=1&sortedBy=desc&orderBy=created_at&include=details.ref.images,details.ref.variation,user
    // this.endPoint = "api/orders";
    this.endPoint = 'api/v2/packing-list'
    const response = await this.get(opts)
    return response.data
  }

  async syncOrder(data: any, params = {}) {
    this.endPoint = 'api/orders/sync'
    return await this.post(data, params)
  }

  async updateOrder(data: any, params = {}) {
    // this.endPoint = `api/orders/${id}`;
    this.endPoint = 'api/v2/orders'
    return await this.put(data, params)
  }

  async updateOrderEdit(data: any, params = {}, id: any) {
    this.endPoint = `api/v2/orders/${id}`
    // this.endPoint = `api/v2/orders`;
    return await this.put(data, params)
  }

  async revisiOrder(data: any, params = {}) {
    this.endPoint = 'api/v2/orders'
    return await this.put(data, params)
  }

  async syncSpecificOrder(id: any, data: any, params = {}) {
    this.endPoint = `api/orders/${id}/sync`
    return await this.post(data, params)
  }

  async updateQuantityOrder(id: any, data: any, params = {}) {
    this.endPoint = 'api/order-details'
    return await this.putOne(id, data, params)
  }

  async editInvoiceNumber(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/order-invoice'
    return await this.putOne(id, data, params)
  }

  // // api/v2/custom-charge-details/2
  // deleteShipmentRequest(id: string | number) {
  //   this.endPoint = 'api/v2/custom-charge-details/'
  //   return this.delete(id).then(response => {
  //     return response
  //   })
  // }

  // Delete customized charge
  async deleteCustomizeChargeById(charge_detail_id) {
    // console.log('charge_detail_id',charge_detail_id)
    this.endPoint = 'api/v2/custom-charge-details'
    return this.delete(charge_detail_id)
  }

  async setDomesticShippingFee(data: any, params = {}) {
    this.endPoint = 'api/invoices'
    return await this.post(data, params)
  }

  // Invoices
  async getInvoices(opts = {}) {
    this.endPoint = 'api/invoices'
    const response = await this.get(opts)
    return response.data
  }

  deleteOrder(id: any, params = {}) {
    this.endPoint = 'api/v2/orders'
    return this.delete(id, params).then(response => {
      return response
    })
  }

  // Documents Order
  async getDocument(id: any,opts = {}) {
    this.endPoint = 'api/v2/documents/orders'
    const response = await this.getOne(id, opts)
    return response.data
  }

  async addDocument(id, data: any, params = {}) {
    this.endPoint = 'api/v2/documents/orders'
    return this.postOne(id, data, params)
  }

  deleteDocument(id: any, params = {}) {
    this.endPoint = 'api/v2/documents'
    return this.delete(id, params).then(response => {
      return response
    })
  }

  async deleteItems(id, data: any, params = {}) {
    this.endPoint = `api/v2/orders/${id}/deleteitems`
    return this.post(data, params)
  }

  async addItems(id, data: any, params = {}) {
    this.endPoint = `api/v2/orders/${id}/additems`
    return this.post(data, params)
  }

  async deleteOrderStatusHistory(id) {
    this.endPoint = `api/v2/orders/status-history`
    return  this.delete(id)
  }
  async editOrderStatusHistory(id: any, data: any, params = {}) {
    this.endPoint = `api/v2/orders/status-history`
    return await this.putOne(id, data, params)
  }
}

export default new OrdersService()
