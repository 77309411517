// node_modules dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import join from 'lodash/join'
import upperCase from 'lodash/upperCase'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import OrdersService from '@/services/Orders'

@Component({
  components: { ProductDetail }
})
export default class Invoices extends BasePage {
  constructor() {
    super()
  }

  // Id Route Params
  routeId: string = ''

  // Invoice Data
  invoiceData: object[] = []
  domesticShippingFee: string | number = 0
  domesticShippingFeeDialog: boolean = false

  maxQuantityValue: string | number = 1000000 // 1 Million

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'productId'
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: true,
      value: 'productName'
    },
    {
      text: 'Product Variation',
      align: 'left',
      sortable: true,
      value: 'productVariation'
    },
    {
      text: 'Price',
      align: 'right',
      sortable: true,
      value: 'price'
    },
    {
      text: 'Quantity',
      align: 'right',
      sortable: true,
      value: 'quantity'
    },
    {
      text: 'Subtotal',
      align: 'right',
      sortable: true,
      value: 'total'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  @Watch('tablePagination', { deep: true })
  onChanged() {
    this.$vuetify.goTo(0)
    this.getInvoice()
  }

  mounted() {
    this.routeId = this.$route.params.id
    this.getInvoice()
  }

  async getInvoice() {
    const opts = {
      params: {
        include: 'invoices.item.ref.details.ref.variation,invoices.item.ref.details.ref.images,user'
      }
    }
    this.tableLoading = true

    try {
      const response = await OrdersService.getOneOrder(this.$route.params.id, opts)
      this.tableItems = []
      this.invoiceData = []

      forEach(response.invoices.data, dataInvoice => {
        let invoiceData = {
          id: dataInvoice.id,
          invoiceId: dataInvoice.invoice_code,
          originalInvoiceId: dataInvoice.original_invoice_code,
          status: upperCase(dataInvoice.status),
          currency: upperCase(dataInvoice.currency),
          totalPrice: this.formatMoney(dataInvoice.total, dataInvoice.currency),
          totalInvoices: dataInvoice.total_invoices,
          totalBillings: dataInvoice.total_billing,
          userName: response.user.full_name,
          refType: dataInvoice.item.ref_type,
          domesticShippingFee: 0
        }

        // Get Products
        forEach(dataInvoice.item.ref.details.data, dataProducts => {
          if (dataProducts.ref_type === 'App\\Entities\\CustomOrderItem') {
            invoiceData.domesticShippingFee = Number(dataProducts.total)
            this.domesticShippingFee = Number(dataProducts.total)
            return
          }
          let product = {
            id: dataProducts.id,
            productId: dataProducts.ref.product_id,
            price: this.formatMoney(dataProducts.price, dataProducts.currency),
            quantity: this.formatNumber(dataProducts.quantity),
            quantityDialog: false,
            total: this.formatMoney(dataProducts.total, dataProducts.currency),
            productName: dataProducts.ref.title,
            refType: dataProducts.ref_type,
            productVariations: [] as string[],
            productImages: [] as object[],
            newQuantity: null as any
          }
          product.newQuantity = this.unformatNumber(product.quantity)

          // Get Product Variations
          forEach(dataProducts.ref.variation.data, dataVariations => {
            product.productVariations.push(`<small>${dataVariations.key}: <b>${dataVariations.value}</b></small>`)
          })

          // Get Product Images
          forEach(dataProducts.ref.images.data, dataImages => {
            product.productImages.push(dataImages.file)
          })
          this.tableItems.push(product)
        })
        this.invoiceData.push(invoiceData)
      })

      this.tableTotalItems = this.tableItems.length
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async updateQuantity(props: any, validationScope: string) {
    try {
      const validationResponse = await this.$validator.validateAll(validationScope)
      if (validationResponse) {
        this.tableLoading = true

        const response = await OrdersService.updateQuantityOrder(props.item.id, { quantity: props.item.newQuantity })
        this.getInvoice()
        this.showSnackbar({ text: startCase(response.data.message), color: 'teal', timeout: 2500 })
      } else {
        this.showSnackbar({ text: 'Please check all filed(s) requirements', color: 'red', timeout: 3500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async setDomesticShippingFee() {
    try {
      const validationResponse = this.$validator.validateAll('domesticShippingFee')
      if (validationResponse) {
        this.domesticShippingFeeDialog = false
        this.showLoading({ text: 'Saving Data...' })

        await OrdersService.setDomesticShippingFee({
          domestic_shipping_fee: this.domesticShippingFee,
          order_id: this.routeId
        })
        this.getInvoice()
        this.showSnackbar({ text: 'Domestic Shipping Fee Updated!', color: 'teal', timeout: 2500 })
      } else {
        this.showSnackbar({ text: 'Please check all filed(s) requirements', color: 'red', timeout: 3500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  getProductVariantAsString(productVariants: string[]) {
    return join(productVariants, '<br>')
  }
}
