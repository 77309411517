// node_modules dependencies
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Viewer from 'v-viewer/src/component.vue'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import find from 'lodash/find'
import Dropzone from 'vue2-dropzone'
import { Watch } from 'vue-property-decorator'

import BasePage from '@/pages/Base'

// Services
import ProductService from '@/services/Products'
import SupplierService from '@/services/Suppliers'

// Mixins
import { formatMoney, formatNumber } from '@/mixins/helper'

// Configs
import config from '@/configs'

// Global Constant Variables
const storage = window.localStorage
const baseUrl = config.api.baseURL


@Component({
  components: { Viewer, Dropzone }
})
export default class ProductDetail extends BasePage {
  constructor() {
    super()
  }

  formatMoney = formatMoney
  formatNumber = formatNumber

  variantPanel: boolean = false
  showDialogDescription: boolean = false
  showDialogVariants: boolean = false
  showDialogExclusiveProduct: boolean = false
  showDialogSetMineImage: boolean = false
  showModalSetWeight: boolean = false
  showModalSetCBM: boolean = false

  titleProduct : string = ''
  product: object = {}
  variantHeader: object[] = [
    {
      text: 'Variant Name',
      align: 'left',
      sortable: true,
      value: 'variantName'
    },
    {
      text: 'Variant Quantity',
      align: 'center',
      sortable: true,
      value: 'quantity',
      class: 'field-table-width'
    },
    {
      text: 'Variant Price',
      align: 'center',
      sortable: true,
      value: 'price',
      class: 'field-table-width'
    },
    {
      text: 'Variant Sale Price',
      align: 'center',
      sortable: true,
      value: 'salePrice',
      class: 'field-table-width'
    },
    {
      text: 'Variant Original SKU',
      align: 'center',
      sortable: true,
      value: 'original_sku',
      class: 'field-table-width'
    },
    {
      text: 'Variant ALI SKU',
      align: 'left',
      sortable: true,
      value: 'ali_sku',
      class: 'field-table-width'
    }
  ]

  isEditTitle = null

  token: string = storage.getItem('access_token')

  options: any = {
    url: 'https://httpbin.org/post', // dummy post data. this will change later after adding an image
    headers: {
      Authorization: 'Bearer ' + this.token,
      Identifier: 'manage'
    },
    addRemoveLinks: true,
    dictDefaultMessage: 'Click Here or Drop Image Here To Upload',
    autoProcessQueue: false,
    acceptedFileTypes: 'image/*',
    maxNumberOfFiles: 10,
    maxFileSizeInMB: 2,
    duplicateCheck: true,
    thumbnailHeight: 250,
    thumbnailWidth: 250,
    paramName: 'images',
    parallelUploads: 10,
    uploadMultiple: true
  }
  selectedMainImages: any = {}
  haveMainImage: boolean = false

  deletedImages: any = []

  // Supplier
  showModalSetSupplier: boolean = false
  supplierNames: any = []
  supplierSelected: string = ''
  supplierKeyword: string = ''
  setSupplierLoading: boolean = false
  supplierHint: string = 'Select supplier here '

  country_product: any = {
    country : {},
    province : {},
    city : {}
  }

  category_product: any = { 
    topCategory: null,
    categoryLevel2: null,
    categoryLevel3: null
  }

  tags_product: any = []

  weight_product: any = {}
  
  // set CBM
  set_cbm : number = 0

  @Prop({ required: false })
  productId: number

  @Prop({ default: () => {} })
  imageOptions: object

  @Prop({ default: false })
  customSlotTitle: boolean

  @Prop({ default: false })
  customSlotDetail: boolean

  @Prop({ default: false })
  hideActions: boolean

  @Prop({ required: false })
  id: number

  mounted() {
    this.getProduct(this.productId)

    ;(this.$refs as any).images.dropzone.on('maxfilesexceeded', file => {
      ;(this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) too much. Max ${this.options.maxNumberOfFiles} Images`,
        color: 'red',
        timeout: 5000
      })
    })   
  }

  @Watch('supplierKeyword')
  onSupplierChanged() {
    this.getSupplier()
  }

  getProduct(productId: number) {
    const opts: any = {
      params: {
        include: 'images,variations,country,provinces,cities,owner,supplier,categories'
      }
    }

    ProductService.getOneProduct(productId, opts).then(response => {
      const dataProduct: any = response
      const responseCategories = response.included.categories
      const responseTags = response.included.tags

      let product = {
        productId: this.productId,
        productName: dataProduct.data.attributes.title,
        description: dataProduct.data.attributes.description ? dataProduct.data.attributes.description : 'No description provided',
        currency: dataProduct.data.attributes.original_currency,
        salePrice: dataProduct.data.attributes.sale_price,
        resellerPrice: dataProduct.data.attributes.reseller_price,
        price: dataProduct.data.attributes.regular_price,
        quantity: dataProduct.data.attributes.quantity,
        quantityUnit: dataProduct.data.attributes.unit,
        cbm: 0,
        cbm_rule: 'No category rule',
        weight: dataProduct.data.attributes.weight,
        weight_rule: 'No category rule', 
        weightUnit: dataProduct.data.attributes.weight_unit,
        weightUnit_rule: 'No category rule',
        images: [] as object[],
        variants: [] as object[],
        created: this.toFullDate(dataProduct.data.attributes.created_at.slice(0, 10)),
        updated: this.toFullDate(dataProduct.data.attributes.updated_at.slice(0, 10))
      }

      if(dataProduct.data.attributes.cbm == 0 && dataProduct.data.attributes.qty_per_cbm != null){
        product.cbm = 1/dataProduct.data.attributes.qty_per_cbm
      } else if (dataProduct.data.attributes.qty_per_cbm == null){
        product.cbm = dataProduct.data.attributes.cbm
      } else product.cbm = dataProduct.data.attributes.cbm
      
      if(!isEmpty(dataProduct.data.attributes.rules.category_rule)){
        product.cbm_rule = dataProduct.data.attributes.rules.category_rule.attributes.cbm
        product.weight_rule = dataProduct.data.attributes.rules.category_rule.attributes.weight
        product.weightUnit_rule = dataProduct.data.attributes.rules.category_rule.attributes.weight_unit
      }

      this.set_cbm = product.cbm

      // Get Product Variants
      if (!isEmpty(dataProduct.included.variations)) {
        forEach(dataProduct.included.variations, dataSkus => {
          if (!isEmpty(dataSkus)) {
            let variant: any = {
              id: dataSkus.attributes.id,
              price: dataSkus.attributes.regular_price,
              quantity: dataSkus.attributes.quantity,
              salePrice: dataSkus.attributes.sale_price,
              resellerPrice: dataSkus.attributes.reseller_price,
              original_sku: dataSkus.attributes.sku,
              ali_sku: dataSkus.attributes.ali_sku,
              variantItems: [] as string[]
            }
            forEach(dataSkus.attributes.options_values, dataVariation => {
              variant.variantId = dataSkus.attributes.id
              variant.variantQuantity = 0
              variant.variantItems.push(`${dataVariation.name}: <strong>${startCase(dataVariation.option)}</strong>`)
            })
            variant.variantName = join(variant.variantItems, '<br>')
            product.variants.push(variant)
          }
        })
      }

      // Images
      const mainImage = find(dataProduct.included.images, mainImage => mainImage.attributes.is_default)
      if (mainImage !== undefined) {
        product.images.push({
          imageId: mainImage.attributes.id,
          imageUrl: mainImage.attributes.source
        })
      }

      forEach(dataProduct.included.images, dataImage => {
        if (mainImage === undefined || mainImage.attributes.id !== dataImage.attributes.id) {
          product.images.push({
            imageId: dataImage.attributes.id,
            imageUrl: dataImage.attributes.source
          })
        }
      })
      this.product = product

      // cataegory from response
      forEach(responseCategories, category => {
        if (category.attributes.level === 1) {
          this.category_product.topCategory = category.attributes.id
        } else if (category.attributes.level === 2) {
          this.category_product.categoryLevel2 = category.attributes.id
        } else if (category.attributes.level === 3) {
          this.category_product.categoryLevel3 = category.attributes.id
        }
      })

      // tags from responce
      forEach(responseTags, tag => {
        this.tags_product.push(tag.attributes.id)
      })

      // weight product to modal set weight
      this.weight_product.weight = product.weight
      this.weight_product.weight_unit = product.weightUnit

      // Modal Supplier data from response
      if (!isEmpty(response.included.supplier)) {
        this.supplierNames.push({
          text: response.included.supplier[response.data.relationships.supplier.id].attributes.name,
          value: response.included.supplier[response.data.relationships.supplier.id].attributes.id
        })
        this.supplierSelected = response.included.supplier[response.data.relationships.supplier.id].attributes.id
      }

      // model country data from response
      this.country_product.country = !isEmpty(dataProduct.data.relationships.country) 
        ? dataProduct.included.country[dataProduct.data.relationships.country.id].attributes
        : null
      this.country_product.province = !isEmpty(dataProduct.data.relationships.provinces)
        ? dataProduct.included.provinces[dataProduct.data.relationships.provinces.id].attributes 
        : null
      this.country_product.city = !isEmpty(dataProduct.data.relationships.cities)
        ? dataProduct.included.cities[dataProduct.data.relationships.cities.id].attributes 
        : null

      this.titleProduct = dataProduct.data.attributes.title

      var img = ''
      var allImg = ''
      forEach(response.data.relationships.images, (image, index) => {
        img = '<img src=\'' + response.included.images[image.id].attributes.source + '\' style=\'max-width:100% !important;vertical-align:middle;margin-bottom:10px;\' />'
        allImg += img
        let mockFile: any = {
          name: `${image.id}_${
            response.included.images[image.id].attributes.source
              .split('/')
              .pop()
              .split('#')[0]
              .split('?')[0]
          }`,
          id: response.included.images[image.id].attributes.id,
          accepted: true,
          kind: 'image',
          status: 'fetched',
          type: 'image/jpeg',
          upload: {
            filename: response.included.images[image.id].attributes.source
              .split('/')
              .pop()
              .split('#')[0]
              .split('?')[0]
          },
          default: response.included.images[image.id].attributes.is_default ? true : false
        }
          ; (this.$refs as any).images.manuallyAddFile(
          mockFile,
          image.file,
          () => {
            console.log('Callback')
          },
          null,
          {
            dontSubstractMaxFiles: true,
            addToFiles: true
          }
        )
        ; (this.$refs as any).images.dropzone.emit('thumbnail', mockFile, response.included.images[image.id].attributes.source)
      })
    })
  }

  editTitle_product(newTitle){
    this.isEditTitle = false
    const payload = {
      data : [{
        id: this.productId,
        attributes: {
          title: newTitle
        }
      }]
    }

    if(this.titleProduct != newTitle) {
      ProductService.updateTitleProduct(payload)
        .then(response => {
          if(response.success == true) {
            this.$emit('refreshProductLists')
            this.getProduct(this.productId)
          }
        })
    }    
  }

  // Image Viewer
  getImageUrl(images: object[]): any {
    return map(images, 'imageUrl')
  }
  $viewer: any
  inited(viewer: any): void {
    this.$viewer = viewer
  }
  show(): void {
    this.$viewer.show()
  }

  

  imageAdded(file) {
    if (file.id === undefined) {
      file.id = file.upload.uuid
    }
    if (file.default) {
      this.setMainImage(file)
    }
    file.previewElement.addEventListener('click', () => {
      this.setMainImage(file)
      if (!isEmpty(this.selectedMainImages)) {
        this.showSnackbar({
          text: `${file.name} is set as main image`,
          color: 'green',
          timeout: 2000
        })
      }
    })
  }
  imageAddedManually(file) {
    // console.log(file)
  }
  imageAddedError(file) {
    if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
      ; (this.$refs as any).images.removeFile(file)
      this.showSnackbar({ text: 'Error! Not an image file(color: s)', color: 'red' })
    } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
      ; (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
        color: 'red',
        timeout: 5000
      })
    }
    this.error()
  }
  imageDuplicate(file) {
    this.showSnackbar({ text: 'Image is already added', color: 'orange', timeout: 2000 })
  }
  imageRemoved(file: any, error: any, xhr: any) {
    if (file.status === 'fetched') this.deletedImages.push(file.id)
  }
  beforeSendingImage(file, xhr, formData) {
    formData.append('type', 'image')
  }
  beforeSendingImageMultiple(file, xhr, formData) {
    formData.append('type', 'image')
  }
  imageUploadSuccess(file, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) this.done()
  }
  imageUploadMultipleSuccess(files, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) {
      let selectedNewImage = false
      forEach(files, (uploadedImage, index) => {
        if (this.selectedMainImages.id === uploadedImage.id) {
          ProductService.setMainImage(this.productId, { image_id: response.data[index].id })
          selectedNewImage = true
        }
      })
      if (!selectedNewImage && !isEmpty(this.selectedMainImages)) {
        ProductService.setMainImage(this.productId, { image_id: this.selectedMainImages.id })
      }
      this.done()
    }
  }
  imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) { }

  setMainImage(file) {
    if (!isEmpty(file)) {
      if (isEmpty(this.selectedMainImages)) {
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id !== this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove('selected-main')
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id === this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove('selected-main')
        this.selectedMainImages = {}
        this.haveMainImage = false
      }
    } else {
      this.haveMainImage = false
    }
  }

  mineImage_dropzone(){ 
    this.showDialogSetMineImage = false

    // delete image
    if(this.deletedImages.length > 0) {
      const payload_deletSetImage = {
        data : [{
          id : this.productId,
          relationships : []
        }]
      }
      for(const image_id of this.deletedImages){
        const imageDelete_data = {
          type : 'images',
          action : 'delete',
          id : image_id
        }
        payload_deletSetImage.data[0].relationships.push(imageDelete_data)
      }
      ProductService.updateBulkCategoryAndTag(payload_deletSetImage)
    }

    // Send Images
    if ((this.$refs as any).images.getQueuedFiles().length > 0) {
      this.showLoading({ text: 'Uploading Images...' })
      ;(this.$refs as any).images.setOption('url', `${baseUrl}api/v2/products/${this.productId}/updateimages`)
      ;(this.$refs as any).images.processQueue()
    } else {
      // set main image if no new images uploaded
      if (!isEmpty(this.selectedMainImages)) {
        ProductService.setMainImage(this.productId, { image_id: this.selectedMainImages.id })
      }
      this.$emit('refreshProductLists')
      this.done()
      this.getProduct(this.productId)
    }
  } 

  done() {
    this.showSnackbar({ text: 'Product successfully saved', color: 'green', timeout: 2000 })
    setTimeout(() => {
      this.closeLoading()
      this.$router.push('/products')
    }, 2000)
  }
  error() {
    this.closeLoading()
    this.showSnackbar({
      text: 'Error! Something Happened. Check your internet connection',
      color: 'red',
      timeout: 4000
    })
  }

  // Supplier
  async getSupplier() {
    try {
      this.setSupplierLoading = true
      const opts: any = {
        params: {include: 'city'}
      }

      if (!isEmpty(this.supplierKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.supplierKeyword
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await SupplierService.getSuppliers(opts)

      forEach(response.data, supplier => {
        const detail = {
          text: supplier.attributes.name,
          value: supplier.attributes.id
        }

        this.supplierNames.push(detail)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.setSupplierLoading = false
    }
  }

  async saveSetSupplier(){
    try{
      const valid = await this.$validator.validateAll()
      if (valid) {
        const payload = {
          data: [
            {
              id: this.productId,
              attributes: { supplier_id :  this.supplierSelected}
            }
          ]
        }
        this.showLoading({ text: 'Saving...' })

        const response = await ProductService.updateBulkCategoryAndTag(payload)
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })
        this.$emit('refreshProductLists')
        this.showModalSetSupplier = false

      } else this.showSnackbar({ 
        text: 'Please check all fields requirements', 
        color: 'red', 
        timeout: 1500 
      })

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
  

  async saveSetCBM(){
    try{
      const payload = {
        data: [
          {
            id: this.productId,
            attributes: { cbm :  this.set_cbm }
          }
        ]
      }
      this.showLoading({ text: 'Saving...' })

      const response = await ProductService.updateBulkCategoryAndTag(payload)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.$emit('refreshProductLists')
      this.showModalSetCBM = false

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

}
